import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import cStyles from '@dkrm/general-libs/theme/styles';
import { Close } from '@dkrm/icons';
import { Colors, StyleSheet, View } from '@dkrm/ui-kit-basic';
import { ButtonWrapper as Button, ModalPortal } from '@dkrm/ui-kit-basic/v2';

import { getFirstLottieDataFromAdvertisements } from 'app-libs/etc/advertisementHelper';
import { momentId } from 'app-libs/etc/dateHelper';
import { useLoadOnIdle } from 'app-libs/hook_modules/lazyLoad';
import { useLockBodyScroll, useLockDrawerSlide } from 'app-libs/layout/utils';
import { closePopupBanner } from 'app-libs/redux_modules/entity_modules/advertisement';
import {
  getAdsByLocation,
  getLastTimeClosePopupBanner,
} from 'app-libs/redux_modules/selectors/advertisement';
import { trackClickedPopupBanner } from 'app-libs/trackers';

import { K__STOREFRONT__HOMEPAGE__POPUP_BANNER } from 'constants/advertisementConstants';

const K_POPUP_BANNER_WIDTH_PROPORTION = 288;
const K_POPUP_BANNER_HEIGHT_PROPORTION = 359;
const K_HOURS_TO_HIDE_POPUP = 12;
const K_POPUP_BANNER_LOTTIE_READY_TIMEOUT = 300;

const PopupBanner = React.memo(() => {
  const dispatch = useDispatch();
  const [isDataReady, setIsDataReady] = useState(false);

  const bannerAds = useSelector((state) =>
    getAdsByLocation(state, K__STOREFRONT__HOMEPAGE__POPUP_BANNER),
  );
  const lastTimeClosePopupBanner = useSelector(getLastTimeClosePopupBanner);

  const closeBanner = useCallback(() => {
    dispatch(closePopupBanner());
  }, [dispatch]);

  const shouldShowBanner = useMemo(() => {
    if (!lastTimeClosePopupBanner) {
      return true;
    }
    const hours = momentId(lastTimeClosePopupBanner).diff(
      momentId(),
      'hours',
      true,
    );
    return Math.abs(hours) >= K_HOURS_TO_HIDE_POPUP;
  }, [lastTimeClosePopupBanner]);

  const { lottieAnimationUrl, lottieToUrl } =
    getFirstLottieDataFromAdvertisements(bannerAds);

  const isShowingPopupBanner =
    shouldShowBanner && Boolean(lottieAnimationUrl) && Boolean(lottieToUrl);

  useLockBodyScroll(isShowingPopupBanner && isDataReady);
  useLockDrawerSlide(isShowingPopupBanner && isDataReady);

  const [Lottie] = useLoadOnIdle(() => import('react-lottie'), {
    shouldPreventLoad: !isShowingPopupBanner,
  });

  if (!isShowingPopupBanner || !Lottie) {
    return null;
  }

  return (
    <ModalPortal>
      <View style={s.modal}>
        <View style={s.modalContainer}>
          <View onClick={closeBanner} style={s.overlay} />
          <View style={s.content}>
            <Button
              to={lottieToUrl}
              linkProps={{ isUsingAnchor: true }}
              onPress={trackClickedPopupBanner}
            >
              {() => (
                <Lottie
                  options={{
                    loop: true,
                    path: lottieAnimationUrl,
                  }}
                  isClickToPauseDisabled
                  width={K_POPUP_BANNER_WIDTH_PROPORTION}
                  height={K_POPUP_BANNER_HEIGHT_PROPORTION}
                  eventListeners={[
                    {
                      eventName: 'data_ready',
                      callback: () =>
                        setTimeout(
                          () => setIsDataReady(true),
                          K_POPUP_BANNER_LOTTIE_READY_TIMEOUT,
                        ),
                    },
                  ]}
                />
              )}
            </Button>
            <View style={s.closeButton} onClick={closeBanner}>
              <Close size={16} style={s.icon} />
            </View>
          </View>
        </View>
      </View>
    </ModalPortal>
  );
});

const s = StyleSheet.create({
  modal: {
    position: 'fixed',
    backgroundColor: 'transparent',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 9998 /* @note(dika) Hacky quickfix to ensure popup banner modal is always behind other modals */,
  },
  modalContainer: {
    ...cStyles.phxxl,
    ...cStyles.flexMiddle,
    ...cStyles.width100,
    ...cStyles.abs,
    top: '-10%',
    height: '120%',
    backgroundColor: Colors.generateRGBA('black', 0.6),
  },
  overlay: {
    ...cStyles.width100,
    ...cStyles.abs,
    height: '120%',
  },
  content: {
    width: K_POPUP_BANNER_WIDTH_PROPORTION,
    height: K_POPUP_BANNER_HEIGHT_PROPORTION,
  },
  closeButtonContainer: {
    width: K_POPUP_BANNER_WIDTH_PROPORTION,
  },
  closeButton: {
    ...cStyles.abs,
    ...cStyles.bgw,
    width: 24,
    height: 24,
    borderRadius: '100%',
    top: -12,
    right: -12,
  },
  icon: {
    ...cStyles.mts,
    ...cStyles.mls,
    color: Colors.C_BLACK_60,
  },
});

export default PopupBanner;
