import { useEffect, useState } from 'react';
import loadable from '@loadable/component';

export const useLoadOnIdle = (dynamicImportFunc, options) => {
  const {
    fallback = null,
    idleTimeout = 2000,
    shouldPreventLoad = false,
  } = options;
  const [shouldLoadComponent, setShouldLoadComponent] = useState(false);

  useEffect(() => {
    if (!shouldPreventLoad) {
      if (window.requestIdleCallback) {
        requestIdleCallback(() => setShouldLoadComponent(true));
      } else {
        setTimeout(() => setShouldLoadComponent(true), idleTimeout);
      }
    }
  }, [setShouldLoadComponent, idleTimeout, shouldPreventLoad]);
  const Component = shouldLoadComponent
    ? loadable(dynamicImportFunc, { fallback })
    : fallback;
  return [Component];
};
