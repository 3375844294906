import { useLayoutEffect, useEffect, useContext } from 'react';
import { DrawerLockContext } from 'app-libs/contexts/DrawerLockContext';

export const useLockBodyScroll = (shouldLockScroll) => {
  useLayoutEffect(() => {
    // Get original body overflow
    const originalStyle = window.getComputedStyle(document.body).overflow;
    // Prevent scrolling on mount
    if (shouldLockScroll) {
      document.body.style.overflow = 'hidden';
    }

    // Re-enable scrolling when component unmounts
    return () => {
      document.body.style.overflow = originalStyle;
    };
  }, [shouldLockScroll]);
};

export const useLockDrawerSlide = (shouldLockDrawer) => {
  const { setShouldLockDrawer } = useContext(DrawerLockContext);
  useEffect(() => {
    // Update redux to lock drawer
    if (shouldLockDrawer) {
      setShouldLockDrawer(true);
    }

    // Re-enable drawer
    return () => {
      setShouldLockDrawer(false);
    };
  }, [shouldLockDrawer, setShouldLockDrawer]);
};
